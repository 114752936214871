import axios from 'axios'
import { getToken, removeToken } from '@/utils/auth'
import { Notify } from 'vant'
import router from '../router'
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, 
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 
})

service.interceptors.request.use(
  config => {
    var token = getToken()
    if (token) {
      config.headers['token'] = token
    }else {
      config.headers['token'] = ''
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code != 200) {
      if (res.code == 401) {
        removeToken()
        router.push({ name: "gologin" });
        Notify(res.message)
        return Promise.reject(new Error(res.message || 'Error'))

      } 
      Notify(res.message)
      return Promise.reject(new Error(res.message || 'Error'))
    }
    return res
  },
  error => {
    Notify(error.message)
    return Promise.reject(error)
  }
)

export default service
